import CMS from "utils/cms";
import React from 'react';
import HomeLayout from '@/template/pages/home';
import fetchCms from "@/AbsoluteComponents/services/cms_interceptor";
import process from "process";


export async function getStaticProps() {
    let types: any[] = [];
    let fetchError = '';

    try {
        const req = await fetchCms('/api/document/get/' + CMS.API_ID_SLOTS);
        const data = await req.json();

        types = data ? data.data.types : [];
    } catch (e: any) {
        fetchError = e.message.toString();
    }

    return {
        props: {
            rows: types,
            fetchError
        },
    }
}

export default function Home(props: any) {

    const {rows, fetchError} = props;
    return <HomeLayout rows={rows}/>
}