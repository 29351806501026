import styles from 'themes/spins/styles/Home.module.css';
import Builder from '@/template/cms-views/builder';
import CMS from "utils/cms";
import React from 'react';
import Game from 'utils/game'



export default function Home(props:any) {

    const {rows} = props;

    const games = Game.getAll()
    // console.log(games)

    return (
        <div className={styles.container}>
            <Builder blocks={rows || []} api_id={CMS.API_ID_SLOTS}/>
        </div>
    )
}